import { Tools } from "../../utilities/Tools";

class FileContentReader {
    constructor(input, settings = {}) {
        this.settings = Object.assign(
            {},
            Tools.parseStringValuesInObjectLiteral(
                (({ dataset }) => {
                    const datasetObject = Object.assign({}, dataset);

                    delete datasetObject.toggle;
                    return datasetObject;
                })(input)
            ),
            Tools.parseStringValuesInObjectLiteral(settings)
        );
        this.input = input;
        this.handleEventBind = this.handleEvent.bind(this);
        this.reader = new FileReader(); // Utworzenie FileReader.
        this._toast = undefined;

        // Inicjalizacja
        this.init();
    }

    get toast() {
        return this._toast;
    }

    set toast(value) {
        this._toast = value;
    }

    events() {
        this.input.addEventListener("change", (event) => {
            this.readFile(event);
        });

        this.reader.addEventListener("loadstart", this.handleEventBind);
        this.reader.addEventListener("progress", this.handleEventBind);
        this.reader.addEventListener("load", this.handleEventBind);
        this.reader.addEventListener("loadend", this.handleEventBind);
        this.reader.addEventListener("abort", this.handleEventBind);
        this.reader.addEventListener("error", this.handleEventBind);
    }

    handleEvent(event) {
        switch (event.type) {
            case "loadstart":
                break;
            case "progress":
                break;
            case "load":
                break;
            case "loadend":
                this.parseFile(this.reader.result);

                break;
            case "abort":
                break;
            case "error":
                break;

            default:
                break;
        }
    }

    readFile(event) {
        const file = event.target.files[0];

        if (file) {
            if (Math.round(Tools.formatSizeUnits(file.size, "MB").replace(" MB", "")) <= 64) {
                this.reader.readAsArrayBuffer(file);
            }
        }
    }

    parseFile(csvString) {
        if (window.Worker) {
            const worker = new Worker(new URL("./worker.js", import.meta.url));

            worker.addEventListener("message", (event) => {
                // Wywołanie zdarzenia po konwersji string'a CSV.
                this.input.dispatchEvent(
                    new CustomEvent("iksorisfilecontentreaderloadend", {
                        bubbles: true,
                        detail: {
                            input: this,
                            data: event.data,
                        },
                    })
                );
            });

            worker.postMessage(csvString);
        }
    }

    render() {
        let html = `<div class="file-content-reader"></div>`;

        // Wyświetlenie pola drag and drop.
        this.input.parentElement.insertAdjacentHTML("beforeend", html);

        // Ustawienie zdarzeń.
        this.events();
    }

    init() {
        //
        this.render();
    }
}

export { FileContentReader };
